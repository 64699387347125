<template>
  <div>
    <div ref="navbar" class="navbar">
      <img
        class="nav_logo"
        src="@/assets/logo.png"
        @click="toHome"
        alt="logo"
      />
      <div class="nav_title" :style="'color:' + color">
        {{ title }}
      </div>
      <img
        class="nav_more"
        :class="moreList ? 'rotate' : ''"
        src="@/assets/more.png"
        @click="showMoreList"
        alt="列表"
      />
    </div>
    <!-- navbar背景图片 -->
    <!-- <img ref="bgimg" class="nav_bgimg" :src="navbarbgimg" width="100%" alt="" /> -->
    <!-- END -->
    <!-- 业务导航页 -->
    <div class="nav_page" :class="moreList ? 'show_nav_page' : ''">
      <ul class="nav_list">
        <div v-for="(item, index) in navList" :key="index">
          <div class="fgx"></div>
          <li class="nav_list_item">
            <h3 @click="expandList(index, item.url)">
              {{ item.title }}
            </h3>
            <ul ref="navListChild" class="nav_list_child">
              <li
                v-for="(childItem, childIndex) in item.child"
                :key="childIndex"
              >
                {{ childItem }}
              </li>
            </ul>
          </li>
        </div>
        <div class="fgx"></div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbar",
  props: ["title", "color"],
  data() {
    return {
      moreList: false, // 是否显示业务列表
      // 更多列表
      navList: [
        // {
        //   title: "职称评审",
        //   child: [],
        //   url: "/ProfessionalReview",
        // },
        // {
        //     title: "建工培训",
        //     child: [],
        //     url: "https://class.lbdkt.com/#/",
        // },
        {
          title: "职业教育",
          child: [],
          url: "http://pc.edu.lbdkt.com/",
        },
        {
          title: "深圳入户",
          child: [],
          url: "/ShenZhenSettle",
        },
        {
          title: "香港移居",
          child: [],
          url: "/HongKongMigrate",
        },
        // {
        //   title: "关于鲁班",
        //   child: [],
        //   url: "/AboutLuBan",
        // },
        {
          title: "联系我们",
          child: [],
          url: "/ContactUs",
        },
      ],
    };
  },
  mounted() {
    // this.generateImages();
  },
  methods: {
    // 此处以 card 部分内容为例，假设我们要将 card 整个 div 的内容区域变为图片
    // generateImages() {
    //   const navbar = this.$refs.navbar;
    //   const bgimg = this.$refs.bgimg;
    //   // 图片参数设置
    //   const opts = {
    //     logging: true,
    //     width: navbar.offsetWidth,
    //     height: navbar.offsetHeight,
    //     // backgroundcolor: "#FFFFFF",
    //     // useCORS: true, // 用于解决图片跨域
    //   };
    //   html2canvas(bgimg, opts).then((canvas) => {
    //     const imgUrl = canvas.toDataURL("image/jpeg");
    //     // console.log(imgUrl);
    //     navbar.style.backgroundImage = `url(${imgUrl})`;
    //   });
    // },
    /**
     * @description 返回首页
     * @author suxiaojun
     * @date 2023/09/08
     **/
    toHome() {
      this.$router.push({ path: "/", query: {} });
    },
    /**
     * @description 显示更多业务列表
     * @author suxiaojun
     * @date 2023/09/01
     **/
    showMoreList() {
      this.moreList = !this.moreList;
    },
    /**
     * @description 展开列表
     * @author suxiaojun
     * @date 2023/09/01
     **/
    expandList(index, url) {
      if (url != "") {
        if (url && url.indexOf("http") != -1) {
          window.open(url, "_blank");
        } else {
          this.$router.push({ path: url, query: {} });
        }
        return;
      }
      if (this.$refs.navListChild[index].style.maxHeight != "95px") {
        this.$refs.navListChild.forEach((data) => {
          data.style.maxHeight = "0px";
        });
        this.$refs.navListChild[index].style.maxHeight = "95px";
      } else {
        this.$refs.navListChild[index].style.maxHeight = "0px";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_bgimg {
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 12px;
  width: 100vw;
  background-size: 100%;
  background-position: 2px 0px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  z-index: 9;

  .nav_logo {
    width: 150px;
  }

  .nav_title {
    margin-left: auto;
    margin-right: 20px;
    font-size: 17px;
  }

  .nav_more {
    width: 22px;
    transition: transform 1s;
  }

  .rotate {
    transform: rotate(-180deg);
  }
}
.show_nav_page {
  top: 0vh !important;
}

.nav_page {
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #ffffff;
  background-color: #002d72;
  background-image: url("@/assets/menu_bg_img.png");
  background-size: cover;
  z-index: 3;
  transition: top 0.5s ease 0s;

  .nav_list {
    position: absolute;
    top: 16%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, 0);

    .fgx {
      width: 100%;
      height: 2px;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 0.4;
    }

    .nav_list_item {
      padding: 18px 0;

      h3 {
        margin: 0;
        font-weight: 100;
        letter-spacing: 2px;
      }
      .nav_list_child {
        max-height: 0px;
        overflow: hidden;
        font-size: 14px;
        letter-spacing: 2px;
        text-align: center;
        transition: max-height 2s ease 0s;

        li {
          margin-top: 18px;
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    text-decoration: none;
    list-style: none;
  }
}
</style>
