import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/ProfessionalReview',
    name: 'ProfessionalReview',
    meta: {
      title: '职称评审'
    },
    component: () => import(/* webpackChunkName: "ProfessionalReview" */ '../views/ProfessionalReview.vue')
  },
  {
    path: '/ShenZhenSettle',
    name: 'ShenZhenSettle',
    meta: {
      title: '深圳入户'
    },
    component: () => import(/* webpackChunkName: "ShenZhenSettle" */ '../views/ShenZhenSettle.vue')
  },
  {
    path: '/HongKongMigrate',
    name: 'HongKongMigrate',
    meta: {
      title: '香港移居'
    },
    component: () => import(/* webpackChunkName: "HongKongMigrate" */ '../views/HongKongMigrate.vue')
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    meta: {
      title: '联系我们'
    },
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs.vue')
  },
  {
    path: '/AboutLuBan',
    name: 'AboutLuBan',
    meta: {
      title: '关于鲁班'
    },
    component: () => import(/* webpackChunkName: "AboutLuBan" */ '../views/AboutLuBan.vue')
  },
  {
    path: '/Education',
    name: 'Education',
    meta: {
      title: '鲁班大课堂'
    },
    component: () => import(/* webpackChunkName: "AboutLuBan" */ '../views/promotion/Education.vue')
  }
]



const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  } else {
    document.title = '鲁班大课堂'
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})

export default router
